interface IdeaLightbulbIcon extends React.ComponentPropsWithRef<'svg'> {
  color?: string;
}
export function IdeaLightbulbIcon({ color = 'currentColor', ...svgProps }: IdeaLightbulbIcon) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M12 22.0002C11.4333 22.0002 10.9542 21.8044 10.5625 21.4127C10.1708 21.021 9.975 20.5419 9.975 19.9752H14.025C14.025 20.5419 13.8292 21.021 13.4375 21.4127C13.0458 21.8044 12.5667 22.0002 12 22.0002ZM7.95 18.4252V16.9252H16.05V18.4252H7.95ZM8.075 15.4002C6.975 14.6835 6.10417 13.7877 5.4625 12.7127C4.82083 11.6377 4.5 10.4252 4.5 9.0752C4.5 7.04186 5.24167 5.28353 6.725 3.8002C8.20833 2.31686 9.96667 1.5752 12 1.5752C14.0333 1.5752 15.7917 2.31686 17.275 3.8002C18.7583 5.28353 19.5 7.04186 19.5 9.0752C19.5 10.4252 19.1833 11.6377 18.55 12.7127C17.9167 13.7877 17.0417 14.6835 15.925 15.4002H8.075ZM8.625 13.9002H15.4C16.2 13.3669 16.8333 12.6752 17.3 11.8252C17.7667 10.9752 18 10.0585 18 9.0752C18 7.4252 17.4125 6.0127 16.2375 4.8377C15.0625 3.6627 13.65 3.0752 12 3.0752C10.35 3.0752 8.9375 3.6627 7.7625 4.8377C6.5875 6.0127 6 7.4252 6 9.0752C6 10.0585 6.23333 10.9752 6.7 11.8252C7.16667 12.6752 7.80833 13.3669 8.625 13.9002Z"
        fill={color}
      />
    </svg>
  );
}
