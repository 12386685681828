interface LockIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  color?: string;
  title?: string;
}

export const LockIcon = ({ color = 'currentColor', ...props }: LockIconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.388 20.44L17.041 20.08C16.9183 20.1983 16.8643 20.3709 16.8977 20.538L17.388 20.44ZM17.8804 22.9019L18.3707 22.8039L17.8804 22.9019ZM14.1196 22.9019L13.6293 22.8039L14.1196 22.9019ZM14.612 20.44L15.1023 20.538C15.1357 20.3709 15.0817 20.1983 14.959 20.08L14.612 20.44ZM7 13.5C7 13.2239 7.22386 13 7.5 13V12C6.67157 12 6 12.6716 6 13.5H7ZM7 26.5V13.5H6V26.5H7ZM7.5 27C7.22386 27 7 26.7761 7 26.5H6C6 27.3284 6.67157 28 7.5 28V27ZM24.5 27H7.5V28H24.5V27ZM25 26.5C25 26.7761 24.7761 27 24.5 27V28C25.3284 28 26 27.3284 26 26.5H25ZM25 13.5V26.5H26V13.5H25ZM24.5 13C24.7761 13 25 13.2239 25 13.5H26C26 12.6716 25.3284 12 24.5 12V13ZM7.5 13H24.5V12H7.5V13ZM17.735 20.7999C18.206 20.3458 18.5 19.7068 18.5 19H17.5C17.5 19.4242 17.3246 19.8066 17.041 20.08L17.735 20.7999ZM18.3707 22.8039L17.8783 20.3419L16.8977 20.538L17.3901 23L18.3707 22.8039ZM17.3901 24C18.0211 24 18.4944 23.4227 18.3707 22.8039L17.3901 23L17.3901 23V24ZM14.6099 24H17.3901V23H14.6099V24ZM13.6293 22.8039C13.5056 23.4227 13.9789 24 14.6099 24V23L14.6099 23L13.6293 22.8039ZM14.1217 20.3419L13.6293 22.8039L14.6099 23L15.1023 20.538L14.1217 20.3419ZM13.5 19C13.5 19.7068 13.794 20.3458 14.265 20.7999L14.959 20.08C14.6754 19.8066 14.5 19.4242 14.5 19H13.5ZM16 16.5C14.6193 16.5 13.5 17.6193 13.5 19H14.5C14.5 18.1716 15.1716 17.5 16 17.5V16.5ZM18.5 19C18.5 17.6193 17.3807 16.5 16 16.5V17.5C16.8284 17.5 17.5 18.1716 17.5 19H18.5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 12.5V9.2381C10 6.34518 12.6863 4 16 4C19.3137 4 22 6.34518 22 9.2381V12.5H10Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
