import classNames from 'classnames';

import { CheckMarkIcon, AlertExclamationPointIcon, LockIcon } from '../icons';

import * as styles from './DecorativeCheckbox.css';
import { useEffect, useRef, useState } from 'react';

interface DecorativeCheckboxProps extends React.ComponentPropsWithoutRef<'div'> {
  boxStyle?: 'round' | 'square';
  checkState?: 'checked' | 'unchecked' | 'indeterminate' | 'locked';
}

/**
 * Renders a decorative checkbox. Note that this is only a decorative component and does not provide any checkbox functionality,
 * especially when it comes to accessibility.
 * If you need to add a working checkbox input, it is recommended to wrap this with the Checkbox component from the shared-ui-components library.
 *
 * @example
 * ```tsx
 * <Checkbox isSelected={isSelected} onChange={setIsSelected}>
 *   <DecorativeCheckbox isChecked={isSelected} />
 * </Checkbox>
 */
export function DecorativeCheckbox({
  boxStyle = 'round',
  checkState = 'unchecked',
  className,
  ...divProps
}: DecorativeCheckboxProps) {
  const previousCheckStateRef = useRef(checkState);

  // Only play state change animations when the checkState changes to ensure
  // nothing gets played on mount
  const [shouldAnimateStateChange, setShouldAnimateStateChange] = useState(false);

  useEffect(() => {
    if (previousCheckStateRef.current !== checkState) {
      previousCheckStateRef.current = checkState;
      setShouldAnimateStateChange(true);
    } else {
      setShouldAnimateStateChange(false);
    }
  }, [checkState]);

  return (
    <div
      className={classNames(styles.decorativeCheckbox, className)}
      {...styles.dataShouldAnimateStateChange(shouldAnimateStateChange)}
      {...styles.dataBoxStyle(boxStyle)}
      {...styles.dataCheckState(checkState)}
      {...divProps}
      // We have to reset `shouldAnimateStateChange` after the animation ends
      // in order to make it possible to replay the animation in cases where we
      // transition between a checked and indeterminate state
      onAnimationEnd={() => setShouldAnimateStateChange(false)}
    >
      <CheckMarkIcon className={styles.checkMarkIcon} />
      <AlertExclamationPointIcon className={styles.alertIcon} />
      <LockIcon className={styles.lockIcon} />
    </div>
  );
}
