interface PaintBrushIconProps extends React.ComponentPropsWithRef<'svg'> {
  color?: string;
}

export function PaintBrushIcon({
  color = 'currentColor',
  strokeWidth = 1.33,
  ...svgProps
}: PaintBrushIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M9.99902 2V4"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9995 2V6"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 2C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V12H6V3C6 2.73478 6.10536 2.48043 6.29289 2.29289C6.48043 2.10536 6.73478 2 7 2H17Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99951 12C5.7343 12 5.47994 12.1054 5.29241 12.2929C5.10487 12.4804 4.99951 12.7348 4.99951 13V14C4.99951 14.5304 5.21023 15.0391 5.5853 15.4142C5.96037 15.7893 6.46908 16 6.99951 16H8.99951C9.26473 16 9.51908 16.1054 9.70662 16.2929C9.89415 16.4804 9.99951 16.7348 9.99951 17V19.9C9.99951 20.4304 10.2102 20.9391 10.5853 21.3142C10.9604 21.6893 11.4691 21.9 11.9995 21.9C12.5299 21.9 13.0387 21.6893 13.4137 21.3142C13.7888 20.9391 13.9995 20.4304 13.9995 19.9V17C13.9995 16.7348 14.1049 16.4804 14.2924 16.2929C14.4799 16.1054 14.7343 16 14.9995 16H16.9995C17.5299 16 18.0387 15.7893 18.4137 15.4142C18.7888 15.0391 18.9995 14.5304 18.9995 14V13C18.9995 12.7348 18.8942 12.4804 18.7066 12.2929C18.5191 12.1054 18.2647 12 17.9995 12"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
