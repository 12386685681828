import {
  Button,
  Label,
  ListBox,
  ListBoxItem,
  ListBoxItemProps,
  Popover,
  Select,
  SelectValue,
} from 'react-aria-components';
import classNames from 'classnames';

import { DownArrowIcon } from '../icons';

import * as styles from './SelectInput.css';

interface SelectInputProps {
  id?: string;
  label?: React.ReactNode;
  ariaLabel?: string;
  ariaLabelledBy?: string;
  className?: string;
  buttonClassName?: string;
  labelClassName?: string;
  /**
   * Class name applies to the span wrapping the currently displayed selected value
   * label for the select input
   */
  selectedValueWrapperClassName?: string;
  /**
   * Additional props to apply to each ListBoxItem component for each option
   */
  optionItemProps?: Omit<ListBoxItemProps, 'children'>;
  placeholder?: string;
  isDisabled?: boolean;
}

/**
 * A controlled select input component that allows users to select from a list of options.
 */
export const SelectInput = <TOptionKey extends string>({
  id,
  label,
  ariaLabel,
  ariaLabelledBy,
  options,
  selectedKey,
  onSelectionChange,
  placeholder,
  isDisabled = false,
  className,
  buttonClassName,
  selectedValueWrapperClassName,
  optionItemProps,
  labelClassName,
}: SelectInputProps & {
  options: ReadonlyArray<{
    /**
     * The name/content to display for the option in the dropdown
     */
    name: string | React.ReactNode;
    /**
     * The unique key to represent each option
     */
    key: TOptionKey;
    /**
     * Additional props to pass to the ListBoxItem component for this option
     */
    itemProps?: Omit<ListBoxItemProps, 'children'>;
  }>;
  selectedKey: TOptionKey;
  onSelectionChange: (newSelectedKey: TOptionKey) => void;
}) => {
  return (
    <Select
      id={id}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      selectedKey={selectedKey}
      onSelectionChange={(newSelectedKey) => onSelectionChange(newSelectedKey as TOptionKey)}
      className={classNames(styles.selectInput, className)}
      isDisabled={isDisabled}
      placeholder={placeholder}
    >
      {label ? <Label className={classNames(styles.label, labelClassName)}>{label}</Label> : null}
      <Button className={classNames(styles.selectButton, buttonClassName)}>
        <SelectValue className={classNames(styles.selectedValue, selectedValueWrapperClassName)} />
        <DownArrowIcon aria-hidden="true" className={styles.arrowIcon} />
      </Button>
      <Popover className={styles.popover}>
        <ListBox items={options} className={styles.listBox}>
          {(option) => {
            const listBoxItemProps = {
              // Apply base option props, then option-specific props
              ...optionItemProps,
              ...option.itemProps,
            };
            // Ensure all classNames are merged
            listBoxItemProps.className = classNames(
              styles.listBoxItem,
              optionItemProps?.className,
              option.itemProps?.className,
            );

            return <ListBoxItem {...listBoxItemProps}>{option.name}</ListBoxItem>;
          }}
        </ListBox>
      </Popover>
    </Select>
  );
};
