interface LeftArrowIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  color?: string;
}

export function LeftArrowIcon({ color = 'currentColor', ...props }: LeftArrowIconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth="1.33333"
      {...props}
    >
      <path d="M10 12L6 8L10 4" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
