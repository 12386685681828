import { forwardRef, useId } from 'react';
import {
  Input,
  InputProps,
  TextField as AriaTextField,
  TextFieldProps,
  Label,
} from 'react-aria-components';
import classNames from 'classnames';

import * as styles from './TextField.css';

interface BaseTextInputProps extends InputProps {
  /**
   * Label to display above the input
   */
  label?: React.ReactNode;
  /**
   * Children passed to the input component will be rendered to appear visually inside the input, aligned to the right side.
   */
  children?: React.ReactNode;
  inputWrapperClassName?: string;
  inputClassName?: string;
}

/**
 * Base text input component which can be composed with other
 * react aria based components. You probably shouldn't directly use this in an app; use the TextInput component below instead.
 */
export const BaseTextInput = forwardRef<HTMLInputElement, BaseTextInputProps>(
  function BaseTextInputComponent(
    { className, children, label, inputWrapperClassName, inputClassName, ...props },
    ref,
  ) {
    return (
      // Using a label to wrap everything so clicks on whitespace around child content will still focus the input
      <Label className={classNames(styles.textInputLabelWrapper, className)}>
        {label ? <span className={styles.labelText}>{label}</span> : null}
        <div className={classNames(styles.textInputWrapper, inputWrapperClassName)}>
          <Input {...props} className={classNames(styles.textInput, inputClassName)} ref={ref} />
          {children}
        </div>
      </Label>
    );
  },
);

export interface TextInputProps extends TextFieldProps {
  label?: React.ReactNode;
  onChange?: (newValue: string) => void;
  /**
   * Error message to display below the input
   */
  errorMessage?: string | null;
  // TextFieldProps does not include `placeholder` but that is a common enough prop that
  // we'll include it here at the top level to reduce the need for inputProps
  placeholder?: string | null;
  inputProps?: Omit<BaseTextInputProps, 'onChange' | 'placeholder'>;
  errorMessageClassName?: string;
  children?: React.ReactNode;
}

/**
 * Basic text input component with support for labels, error messages, and disabled state.
 */
export const TextField = forwardRef<HTMLInputElement, TextInputProps>(function TextInputComponent(
  {
    onChange,
    label,
    errorMessage,
    className,
    placeholder,
    autoComplete,
    inputMode,
    inputProps,
    errorMessageClassName,
    children,
    ...textFieldProps
  },
  ref,
) {
  const instanceID = useId();

  const hasError = Boolean(errorMessage);
  const errorMessageID = hasError ? `${instanceID}-error` : undefined;

  return (
    <AriaTextField
      className={classNames(styles.textField, className)}
      aria-describedby={errorMessageID}
      {...styles.dataHasError(hasError)}
      {...textFieldProps}
    >
      <BaseTextInput
        ref={ref}
        label={label}
        placeholder={placeholder ?? undefined}
        inputMode={inputMode}
        autoComplete={autoComplete}
        onChange={
          onChange
            ? (evt) => {
                onChange(evt.target.value);
              }
            : undefined
        }
        {...inputProps}
      >
        {children}
      </BaseTextInput>
      {hasError ? (
        <small
          id={errorMessageID}
          className={classNames(styles.errorMessage, errorMessageClassName)}
        >
          {errorMessage}
        </small>
      ) : null}
    </AriaTextField>
  );
});
