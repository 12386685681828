import {
  Popover as AriaPopover,
  PopoverProps as AriaPopoverProps,
  Dialog,
  OverlayArrow,
} from 'react-aria-components';
import classNames from 'classnames';

import * as styles from './Popover.css';
import { forwardRef } from 'react';

export interface PopoverProps {
  children: React.ReactNode;
  triggerRef: React.RefObject<HTMLElement>;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  placement?: AriaPopoverProps['placement'];
  shouldFlip?: AriaPopoverProps['shouldFlip'];
  className?: string;
  ariaLabel?: string;
  /**
   * Whether the page contents outside of the popover should be locked from interaction when the popover is open.
   * The main effect of this is that scrolling is locked if isNonModal=false, but if isNonModal=true,
   * scrolling is allowed and will auto-dismiss the popover.
   *
   * @default false
   */
  isNonModal?: boolean;
  shouldCloseOnInteractOutside?: (element: Element) => boolean;
}

export const Popover = forwardRef<HTMLElement, PopoverProps>(function PopoverComponent(
  {
    triggerRef,
    isOpen,
    onOpenChange,
    placement,
    shouldFlip = true,
    className,
    ariaLabel,
    isNonModal = false,
    shouldCloseOnInteractOutside,
    children,
  },
  ref,
) {
  return (
    <AriaPopover
      triggerRef={triggerRef}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      className={classNames(styles.Popover, className)}
      shouldCloseOnInteractOutside={shouldCloseOnInteractOutside}
      isNonModal={isNonModal}
      placement={placement}
      shouldFlip={shouldFlip}
      ref={ref}
    >
      <OverlayArrow>
        <svg width={12} height={12} viewBox="0 0 8 8" className={styles.ArrowIcon}>
          <path d="M0 0 L4 4 L8 0" fill="currentColor" />
        </svg>
      </OverlayArrow>
      <Dialog className={classNames(styles.PopoverDialog, className)} aria-label={ariaLabel}>
        {children}
      </Dialog>
    </AriaPopover>
  );
});
