import classNames from 'classnames';
import { modalHeader } from './ModalHeaderSection.css';

interface ModalHeaderSection {
  /**
   * Custom children to render inside the header section.
   */
  children?: React.ReactNode;
  /**
   * Optional class name to apply custom styles
   */
  className?: string;
}

/**
 * A single button to dismiss a modal.
 */
export const ModalHeaderSection = ({ children, className }: ModalHeaderSection) => (
  <div className={classNames(modalHeader, className)}>{children}</div>
);
