interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
  color?: string;
}

export const RightArrowIcon = ({ color = 'currentColor', ...props }: IconProps) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.33333"
    {...props}
  >
    <path d="M12 6L23 16L12 26.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
