import {
  Button,
  ComboBox,
  Label,
  ListBox,
  ListBoxItem,
  ListBoxItemProps,
  Popover,
} from 'react-aria-components';
import classNames from 'classnames';

import { DownArrowIcon } from '../icons';
import { BaseTextInput } from '../TextField/TextField';

import * as styles from './SearchableSelectInput.css';

interface SearchableSelectInputProps {
  label?: React.ReactNode;
  id?: string;
  ariaLabel?: string;
  ariaLabelledBy?: string;

  className?: string;
  /**
   * Additional props to apply to each ListBoxItem component for each option
   */
  optionItemProps?: Omit<ListBoxItemProps, 'children'>;
}

/**
 * NOTE: this component's styles are not finalized. This will need some cleanup
 * before it can be used in production, but is otherwise available if a design calls for it.
 */
export function SearchableSelectInput<TOptionKey extends string>({
  label,
  id,
  ariaLabel,
  ariaLabelledBy,
  options,
  selectedKey,
  onSelectionChange,
  optionItemProps,
}: SearchableSelectInputProps & {
  options: ReadonlyArray<{
    /**
     * The name/content to display for the option in the dropdown
     */
    name: string | React.ReactNode;
    /**
     * The unique key to represent each option
     */
    key: TOptionKey;
    /**
     * Additional props to pass to the ListBoxItem component for this option
     */
    itemProps?: Omit<ListBoxItemProps, 'children'>;
  }>;
  selectedKey: TOptionKey;
  onSelectionChange: (newSelectedKey: TOptionKey) => void;
}) {
  return (
    <ComboBox
      selectedKey={selectedKey}
      onSelectionChange={(newSelectedKey) => onSelectionChange(newSelectedKey as TOptionKey)}
      id={id}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      className={styles.searchableSelectInput}
    >
      <BaseTextInput label={label}>
        <Button>
          <DownArrowIcon aria-hidden="true" className={styles.arrowIcon} />
        </Button>
      </BaseTextInput>
      <Popover className={styles.popover} isOpen>
        <ListBox items={options} className={styles.listBox}>
          {(option) => {
            const listBoxItemProps = {
              // Apply base option props, then option-specific props
              ...optionItemProps,
              ...option.itemProps,
            };
            // Ensure all classNames are merged
            listBoxItemProps.className = classNames(
              styles.listBoxItem,
              optionItemProps?.className,
              option.itemProps?.className,
            );

            return <ListBoxItem {...listBoxItemProps}>{option.name}</ListBoxItem>;
          }}
        </ListBox>
      </Popover>
    </ComboBox>
  );
}
