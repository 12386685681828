interface AISparkleIconProps extends React.ComponentPropsWithRef<'svg'> {
  color?: string;
}

export function AISparkleIcon({ color = 'currentColor', ...svgProps }: AISparkleIconProps) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <rect
        x="5.94971"
        y="5.5"
        width="7"
        height="7"
        rx="0.25"
        transform="rotate(45 5.94971 5.5)"
        fill={color}
      />
      <rect
        x="11.8284"
        y="1.5"
        width="4"
        height="4"
        rx="0.25"
        transform="rotate(45 11.8284 1.5)"
        fill={color}
      />
    </svg>
  );
}
