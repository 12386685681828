interface AlertExclamationPointIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  color?: string;
}

export function AlertExclamationPointIcon({
  color = 'currentColor',
  ...props
}: AlertExclamationPointIconProps) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M16 9V17.02" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16 23H16.0201" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
