import classNames from 'classnames';
import * as styles from './ModalSubheadingText.css';
import { modalLayoutSlots } from './ModalLayout';

interface ModalSubheadingTextProps extends React.ComponentPropsWithoutRef<'p'> {
  children: React.ReactNode;
}

export const ModalSubheadingText = ({
  children,
  className,
  ...props
}: ModalSubheadingTextProps) => (
  <p
    className={classNames(styles.subheadingText, className)}
    slot={modalLayoutSlots.description}
    {...props}
  >
    {children}
  </p>
);
