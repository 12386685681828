interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
  color?: string;
}

export const CheckMarkIcon = ({ color = 'currentColor', ...props }: IconProps) => (
  <svg
    width="12"
    height="11"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.5"
    {...props}
  >
    <path
      d="M2.56152 6.46154L4.94133 8.625L9.43652 2.375"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
