// Initializes settings for the Waymark client lib entrypoint
import { settings } from '@libs/global-settings';

// Initialize global settings
const settingsConfig = (window as any).__RUNTIME_CONFIG__['core/settings'];
settings
  .set('assets.imgixDomain', settingsConfig.IMGIX_DOMAIN)
  .set('assets.s3.env', settingsConfig.APP_ENVIRONMENT)
  .set('service.aps.assetHost', settingsConfig.APS_ASSET_HOST)
  .set('service.bfs.apiEndpoint', settingsConfig.BFS_API_ENDPOINT)
  .set('service.bfs.s3Host', settingsConfig.BFS_S3_URL)
  .set('waymark.csrfCookieName', settingsConfig.CSRF_COOKIE_NAME);
// APP_ENVIRONMENT was unwisely swapped at some point so that it
// represents the environment to use for S3 assets, not the actual environment we're in.
// This has miraculously managed to not break anything because we mostly only use it to
// check if we're in "prod" or not, and we also use "prod" for the S3 environment.
// So, this heuristic works, but beware, thar be dragons, etc etc
if (settingsConfig.APP_ENVIRONMENT === 'prod') {
  settings.set('waymark.env', 'prod');
} else if (window.location.hostname === 'localhost') {
  settings.set('waymark.env', 'local');
} else {
  settings.set('waymark.env', 'dev');
}

// Browser-friendly renderer settings
settings
  .set('renderingEngine.areAssetDataWarningsEnabled', false)
  .set('renderingEngine.assetQuality', 'medium')
  .set('renderingEngine.effectQuality', 'low');
