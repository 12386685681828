import {
  Tooltip as AriaTooltip,
  TooltipTrigger as AriaTooltipTrigger,
  OverlayArrow as AriaOverlayArrow,
  TooltipProps as AriaTooltipProps,
} from 'react-aria-components';
import { useButton } from 'react-aria';
import classNames from 'classnames';
import { useRef } from 'react';

import * as styles from './Tooltip.css';

export interface TooltipProps extends Omit<AriaTooltipProps, 'children' | 'className'> {
  /**
   * The content which we want to display a tooltip over on hover/focus.
   */
  children: React.ReactNode;
  /**
   * The text to display in the tooltip.
   */
  tooltipText: string;
  /**
   * Class name to apply custom styles to the tooltip trigger button wrapping the provided child content.
   */
  className?: string;
  /**
   * Class name to apply custom styles to the tooltip.
   */
  tooltipClassName?: string;
  /**
   * Whether the tooltip may include content which the user can interact with.
   *
   * @default false
   */
  isInteractable?: boolean;
  /**
   * Function to call when the tooltip trigger button is clicked.
   */
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

/**
 * We need to make a custom trigger button using the useButton hook so we can use
 * a div as the trigger element for the tooltip. We want to use a div to avoid
 * potential scenarios where we generate invalid HTML by accidentally having a button
 * nested inside a button.
 * This needs to live in a separate component because we need to be able to call the
 * useButton hook inside of the TooltipTrigger component because the hook references
 * a provider rendered by the TooltipTrigger component in order to hook up the button's
 * events to the tooltip.
 */
const FocusableTooltipTrigger = (props: React.ComponentPropsWithoutRef<'div'>) => {
  const triggerRef = useRef<HTMLDivElement>(null);
  const { buttonProps } = useButton(
    {
      elementType: 'div',
    },
    triggerRef,
  );

  return <div {...buttonProps} {...props} ref={triggerRef} />;
};

/**
 * Component to display a tooltip on hover/focus over some provided child content.
 */
export const Tooltip = ({
  tooltipText,
  children,
  className,
  tooltipClassName,
  onClick,
  isInteractable = false,
  ...props
}: TooltipProps) => {
  return (
    <AriaTooltipTrigger delay={0} closeDelay={0}>
      <FocusableTooltipTrigger
        className={classNames(styles.TriggerButton, className)}
        onClick={onClick}
      >
        {children}
      </FocusableTooltipTrigger>
      <AriaTooltip
        className={classNames(styles.Tooltip, tooltipClassName)}
        {...styles.dataIsInteractable(isInteractable)}
        offset={8}
        {...props}
      >
        {tooltipText}
        <AriaOverlayArrow>
          <svg width={12} height={12} viewBox="0 0 8 8" className={styles.ArrowIcon}>
            <path d="M0 0 L4 4 L8 0" fill="currentColor" />
          </svg>
        </AriaOverlayArrow>
      </AriaTooltip>
    </AriaTooltipTrigger>
  );
};
