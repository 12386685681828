interface VoiceOverSpeakerIconProps extends React.ComponentPropsWithRef<'svg'> {
  color?: string;
}

export function VoiceOverSpeakerIcon({
  color = 'currentColor',
  strokeWidth = 1.33,
  ...svgProps
}: VoiceOverSpeakerIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M20.3149 1.25C21.2349 2.965 21.7499 4.925 21.7499 7C21.7499 9.075 21.2349 11.035 20.3149 12.75"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M9.5 11.25C11.8472 11.25 13.75 9.34721 13.75 7C13.75 4.65279 11.8472 2.75 9.5 2.75C7.15279 2.75 5.25 4.65279 5.25 7C5.25 9.34721 7.15279 11.25 9.5 11.25Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit={10}
      />
      <path
        d="M16.75 17C16.75 19.347 13.504 21.25 9.5 21.25C5.496 21.25 2.25 19.347 2.25 17V16.25C2.25 15.4215 2.9215 14.75 3.75 14.75H15.25C16.0785 14.75 16.75 15.4215 16.75 16.25V17Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
      />
      <path
        d="M16.8418 10.75C17.4183 9.624 17.7498 8.352 17.7498 7C17.7498 5.648 17.4183 4.376 16.8418 3.25"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
}
