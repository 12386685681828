import { useMemo } from 'react';
import { LocationDescriptorObject } from 'history';
import { Link } from 'react-router-dom';

import { addLocationSearchToURL, addPersistedQueryParamsToSearchString } from 'shared/utils/urls';

import { withBaseWaymarkLink, LinkProps } from './withBaseWaymarkLink';

/**
 * A wrapper around the react-router-dom Link component with Waymark's styling
 * and lots of props for further styling and functionality customization.
 *
 * As the name suggests, this component should be used for internal links, meaning
 * links to other pages within the Waymark SPA.
 */
export const InternalLink = withBaseWaymarkLink<LinkProps>(function InternalLinkComponent(
  { linkTo, ...props },
  ref,
) {
  // Boil down the linkTo prop to a safer format that the Link component can understand.
  // This is necessary because URL objects effectively just get treated as LocationDescriptorObjects
  // since they share enough properties, but this can lead to weird unexpected behavior where links
  // don't work as expected.
  const formattedLinkTo: string | LocationDescriptorObject = useMemo(() => {
    if (typeof linkTo === 'string') {
      // Ensure that we retain persistent query params when navigating to internal links
      return addLocationSearchToURL(linkTo);
    }

    if (linkTo instanceof URL) {
      if (linkTo.origin !== window.location.origin) {
        throw new Error(
          `InternalLink received an external URL ${linkTo.href}. Use ExternalLink instead.`,
        );
      }

      return {
        pathname: linkTo.pathname,
        hash: linkTo.hash,
        search: addPersistedQueryParamsToSearchString(linkTo.search),
      };
    }

    return {
      ...linkTo,
      search: addPersistedQueryParamsToSearchString(linkTo.search),
    };
  }, [linkTo]);

  return <Link to={formattedLinkTo} ref={ref} {...props} />;
});

export type InternalLinkProps = React.ComponentProps<typeof InternalLink>;
