interface VoiceOverScriptMicrophoneIconProps extends React.ComponentPropsWithRef<'svg'> {
  color?: string;
}

export function VoiceOverScriptMicrophoneIcon({
  color = 'currentColor',
  strokeWidth = 1.33,
  ...svgProps
}: VoiceOverScriptMicrophoneIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M12 2C11.2044 2 10.4413 2.31607 9.87868 2.87868C9.31607 3.44129 9 4.20435 9 5V12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12V5C15 4.20435 14.6839 3.44129 14.1213 2.87868C13.5587 2.31607 12.7956 2 12 2Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9995 10V12C18.9995 13.8565 18.262 15.637 16.9493 16.9497C15.6365 18.2625 13.856 19 11.9995 19C10.143 19 8.36252 18.2625 7.04976 16.9497C5.73701 15.637 4.99951 13.8565 4.99951 12V10"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 19V22"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
