import { motion, TargetAndTransition, Transition } from 'framer-motion';

const RECT_1_FRAMES = [
  {
    x: 2.4502,
    y: 6.5,
    width: 7,
    height: 7,
    rotateZ: 45,
  },
  {
    x: 3,
    y: 7.25,
    width: 6,
    height: 6,
    rotateZ: 45,
  },
  // HOLD
  {
    x: 3,
    y: 7.25,
    width: 6,
    height: 6,
    rotateZ: 45,
  },
  {
    x: 3,
    y: 5,
    width: 6,
    height: 6,
    rotateZ: 90,
  },
  // HOLD
  {
    x: 3,
    y: 5,
    width: 6,
    height: 6,
    rotateZ: 90,
  },
  {
    x: 1,
    y: 7,
    width: 6,
    height: 2,
    rotateZ: 90,
  },
  // HOLD
  {
    x: 1,
    y: 7,
    width: 6,
    height: 2,
    rotateZ: 90,
  },
  {
    x: 1,
    y: 10,
    width: 6,
    height: 2,
    rotateZ: 90,
  },
  // HOLD
  {
    x: 1,
    y: 10,
    width: 6,
    height: 2,
    rotateZ: 90,
  },
  {
    x: 2.82812,
    y: 9.84,
    width: 4,
    height: 4,
    rotateZ: 45,
  },
  // HOLD
  {
    x: 2.82812,
    y: 9.84,
    width: 4,
    height: 4,
    rotateZ: 45,
  },
  // Duplicate of 1st frame to loop
  {
    x: 2.4502,
    y: 6.5,
    width: 7,
    height: 7,
    rotateZ: 45,
  },
];

const RECT_2_FRAMES = [
  {
    x: 9.8281,
    y: 1.75,
    width: 4,
    height: 4,
    rotateZ: 45,
  },
  {
    x: 11,
    y: 2,
    width: 2,
    height: 6,
    rotateZ: -180,
  },
  // HOLD
  {
    x: 11,
    y: 2,
    width: 2,
    height: 6,
    rotateZ: -180,
  },
  {
    x: 11,
    y: 5,
    width: 2,
    height: 6,
    rotateZ: -180,
  },
  // HOLD
  {
    x: 11,
    y: 5,
    width: 2,
    height: 6,
    rotateZ: -180,
  },
  {
    x: 7,
    y: 5,
    width: 6,
    height: 6,
    rotateZ: -180,
  },
  // HOLD
  {
    x: 7,
    y: 5,
    width: 6,
    height: 6,
    rotateZ: -180,
  },
  {
    x: 7,
    y: 3,
    width: 6,
    height: 6,
    rotateZ: 135,
  },
  // HOLD
  {
    x: 7,
    y: 3,
    width: 6,
    height: 6,
    rotateZ: 135,
  },
  {
    x: 7.707,
    y: 2.25,
    width: 6,
    height: 6,
    rotateZ: 45,
  },
  // HOLD
  {
    x: 7.707,
    y: 2.25,
    width: 6,
    height: 6,
    rotateZ: 45,
  },
  // Duplicate of 1st frame to loop
  {
    x: 9.8281,
    y: 1.75,
    width: 4,
    height: 4,
    rotateZ: 45,
  },
];

const FRAME_COUNT = RECT_1_FRAMES.length;

const RECT_1_ANIMATION: TargetAndTransition = RECT_1_FRAMES.reduce(
  (acc, frame, index) => {
    acc.attrX[index] = frame.x;
    acc.attrY[index] = frame.y;
    acc.width[index] = frame.width;
    acc.height[index] = frame.height;
    acc.rotateZ[index] = frame.rotateZ;

    return acc;
  },
  {
    attrX: new Array<number>(FRAME_COUNT),
    attrY: new Array<number>(FRAME_COUNT),
    width: new Array<number>(FRAME_COUNT),
    height: new Array<number>(FRAME_COUNT),
    rotateZ: new Array<number>(FRAME_COUNT),
  },
);

const RECT_2_ANIMATION: TargetAndTransition = RECT_2_FRAMES.reduce(
  (acc, frame, index) => {
    acc.attrX[index] = frame.x;
    acc.attrY[index] = frame.y;
    acc.width[index] = frame.width;
    acc.height[index] = frame.height;
    acc.rotateZ[index] = frame.rotateZ;

    return acc;
  },
  {
    attrX: new Array<number>(FRAME_COUNT),
    attrY: new Array<number>(FRAME_COUNT),
    width: new Array<number>(FRAME_COUNT),
    height: new Array<number>(FRAME_COUNT),
    rotateZ: new Array<number>(FRAME_COUNT),
  },
);

const ANIMATION_DURATION = 2;

const ANIMATION_TIMINGS = new Array<number>();
for (let i = 0; i < FRAME_COUNT; i++) {
  ANIMATION_TIMINGS[i] = i / FRAME_COUNT;
}

const TRANSITION_CONFIG: Transition = {
  duration: ANIMATION_DURATION,
  repeat: Infinity,
  times: ANIMATION_TIMINGS,
};

interface AnimatedAISparkleIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  color?: string;
}

export function AnimatedAISparkleIcon({
  color = 'currentColor',
  ...svgProps
}: AnimatedAISparkleIconProps) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <motion.rect
        rx="0.25"
        fill={color}
        style={{
          originX: '50%',
          originY: '50%',
          transformBox: 'fill-box',
        }}
        animate={RECT_1_ANIMATION}
        transition={TRANSITION_CONFIG}
      />
      <motion.rect
        rx="0.25"
        fill={color}
        style={{
          originX: '50%',
          originY: '50%',
          transformBox: 'fill-box',
        }}
        animate={RECT_2_ANIMATION}
        transition={TRANSITION_CONFIG}
      />
    </svg>
  );
}
