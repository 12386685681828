import { RadioGroup as AriaRadioGroup, Label, Radio } from 'react-aria-components';
import classNames from 'classnames';

import * as styles from './RadioGroup.css';

interface RadioGroupProps {
  /**
   * Label content to display above the radio group.
   * This will be used to label the input for screen readers.
   */
  label: React.ReactNode;
  id?: string;
  className?: string;
  labelClassName?: string;
  radioOptionListClassName?: string;
  radioOptionClassName?: string;
  /**
   * How the radio options should be oriented. Defaults to 'vertical' so they will be stacked vertically.
   */
  orientation?: 'vertical' | 'horizontal';
  /**
   * Icon to display next to each radio option, ie an empty circle for unselected options and a filled circle + checkmark for the selection option.
   * This will probably look bad in horizontal orientation.
   */
  optionIcons?: {
    checked: React.ReactNode;
    unchecked: React.ReactNode;
  } | null;
}

export function RadioGroup<TOption extends string>({
  label,
  id,
  value,
  options,
  optionIcons,
  onChange,
  orientation = 'vertical',
  className,
  labelClassName,
  radioOptionListClassName,
  radioOptionClassName,
}: RadioGroupProps & {
  value: TOption | string | null;
  options: ReadonlyArray<{
    /**
     * Key identifying the option. If not provided, the value will be used as the key.
     * This can be useful if the value may change due to a loading state.
     */
    key?: string;
    value: TOption | null;
    label: React.ReactNode;
  }>;
  onChange: (value: TOption) => void;
}) {
  return (
    <AriaRadioGroup
      id={id}
      value={value}
      onChange={(newValue) => {
        if (newValue === undefined) {
          // newValue can end up undefined in weird situations if the user is navigating with a keyboard and
          // an option has an icon which is focusable (ie, a button or a link)
          // We probably shouldn't put buttons/links inside radio options, but just in case, we'll ignore these events
          return;
        }
        onChange(newValue as TOption);
      }}
      className={classNames(styles.radioGroup, className)}
      orientation={orientation}
    >
      <Label className={classNames(styles.radioGroupLabel, labelClassName)}>{label}</Label>
      <div className={classNames(styles.radioOptionList, radioOptionListClassName)}>
        {options.map((option, index) => {
          const isSelected = value === option.value;
          let optionIcon: React.ReactNode | null = null;
          if (optionIcons) {
            optionIcon = isSelected ? optionIcons.checked : optionIcons.unchecked;
          }

          const optionValue = option.value ?? `wm__radioGroup__noValue__${index}`;
          const optionKey = option.key ?? optionValue;

          return (
            <Radio
              key={optionKey}
              value={optionValue}
              className={classNames(styles.radioOption, radioOptionClassName)}
              isDisabled={option.value === null}
            >
              {option.label}
              {optionIcon ? <div className={styles.optionIconWrapper}>{optionIcon}</div> : null}
            </Radio>
          );
        })}
      </div>
    </AriaRadioGroup>
  );
}
