interface SmallImageAssetIconProps extends React.ComponentPropsWithRef<'svg'> {
  color?: string;
}

/**
 * Version of the image asset icon which is intended to be used in contexts where it is
 * displayed at a smaller size.
 */
export const SmallImageAssetIcon = ({
  color = 'currentColor',
  ...props
}: SmallImageAssetIconProps) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth={0.75}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.3125 6C1.3125 5.46764 1.3125 5.20146 1.33711 4.97748C1.54766 3.06093 3.06093 1.54766 4.97748 1.33711C5.20146 1.3125 5.46764 1.3125 6 1.3125V1.3125C6.53236 1.3125 6.79854 1.3125 7.02252 1.33711C8.93907 1.54766 10.4523 3.06093 10.6629 4.97748C10.6875 5.20146 10.6875 5.46764 10.6875 6V6C10.6875 6.53236 10.6875 6.79854 10.6629 7.02252C10.4523 8.93907 8.93907 10.4523 7.02252 10.6629C6.79854 10.6875 6.53236 10.6875 6 10.6875V10.6875C5.46764 10.6875 5.20146 10.6875 4.97748 10.6629C3.06093 10.4523 1.54766 8.93907 1.33711 7.02252C1.3125 6.79854 1.3125 6.53236 1.3125 6V6Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 9L4.31476 7.15732C4.58822 6.77407 5.1577 6.77407 5.43115 7.15732L6.74591 9L7.35272 8.29334C7.62635 7.97468 8.11956 7.97468 8.39319 8.29334L9 9"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.875 5.25C8.49632 5.25 9 4.74632 9 4.125C9 3.50368 8.49632 3 7.875 3C7.25368 3 6.75 3.50368 6.75 4.125C6.75 4.74632 7.25368 5.25 7.875 5.25Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
