import classNames from 'classnames';
import { forwardRef, useId } from 'react';
import {
  TextArea as AriaTextArea,
  TextAreaProps as AriaTextAreaProps,
  Label,
  TextField,
  TextFieldProps,
} from 'react-aria-components';

import * as styles from './TextArea.css';
import { assignInlineVars } from '@vanilla-extract/dynamic';

interface TextAreaProps extends TextFieldProps {
  label?: React.ReactNode;
  value: string;
  onChange: (newValue: string) => void;
  placeholder?: string;
  minRows?: number;
  errorMessage?: string;
  labelWrapperClassName?: string;
  textAreaClassName?: string;
  errorMessageClassName?: string;
  labelClassName?: string;
  textAreaProps?: Omit<AriaTextAreaProps, 'onChange' | 'className' | 'style'>;
}

/**
 * A text area component with support for labels, error messages, and disabled state.
 * Automatically resizes height based on content.
 */
export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(function TextAreaComponent(
  {
    value,
    onChange,
    label,
    placeholder,
    minRows = 2,
    errorMessage,
    isInvalid = Boolean(errorMessage),
    className,
    style,
    labelWrapperClassName,
    labelClassName,
    textAreaClassName,
    errorMessageClassName,
    textAreaProps,
    ...textFieldProps
  },
  ref,
) {
  const instanceID = useId();

  const errorMessageID = errorMessage ? `${instanceID}-error` : undefined;

  return (
    <TextField
      className={classNames(styles.textField, className)}
      aria-describedby={errorMessageID}
      isInvalid={isInvalid}
      value={value}
      style={{
        ...assignInlineVars({
          [styles.minRows]: String(minRows),
        }),
        ...style,
      }}
      {...textFieldProps}
    >
      <Label className={classNames(styles.textAreaLabelWrapper, labelWrapperClassName)}>
        {label ? (
          <span className={classNames(styles.labelText, labelClassName)}>{label}</span>
        ) : null}
        <div className={styles.textAreaAutosizeWrapper}>
          <AriaTextArea
            ref={ref}
            placeholder={placeholder}
            onChange={onChange ? (evt) => onChange(evt.target.value) : undefined}
            className={classNames(styles.textArea, textAreaClassName)}
            {...textAreaProps}
          />
          <div
            className={classNames(styles.autosizeReplicator, textAreaClassName)}
            aria-hidden="true"
          >
            {value + ' '}
          </div>
        </div>
      </Label>
      {errorMessage && errorMessageID ? (
        <small
          id={errorMessageID}
          className={classNames(styles.errorMessage, errorMessageClassName)}
        >
          {errorMessage}
        </small>
      ) : null}
    </TextField>
  );
});
