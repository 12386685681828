import classNames from 'classnames';
import { OverlayArrow, Tooltip, TooltipProps, TooltipTrigger } from 'react-aria-components';

import * as styles from './Tooltip.css';

export interface ControlledTooltipProps extends Omit<TooltipProps, 'onOpenChange'> {
  /**
   * Whether the tooltip is open or not.
   */
  isOpen: boolean;
  /**
   * The ref of the element that the tooltip should be positioned relative to.
   */
  triggerRef: React.RefObject<Element>;
  children: React.ReactNode;
  /**
   * Whether the tooltip may include content which the user can interact with.
   *
   * @default true
   */
  isInteractable?: boolean;
}

/**
 * A custom controlled tooltip with no default trigger button behavior.
 */
export function ControlledTooltip({
  isOpen,
  triggerRef,
  children,
  offset = 8,
  style,
  className,
  isInteractable = true,
  ...tooltipProps
}: ControlledTooltipProps) {
  return (
    <TooltipTrigger isOpen={isOpen}>
      <Tooltip
        triggerRef={triggerRef}
        offset={offset}
        style={{
          // RAC's default z-index is 10000, which will overpower modals. We'll manually bump it down to
          // 1000 to avoid this. RAC sets z index via the style prop, so we need to override it here.
          zIndex: 1000,
          ...style,
        }}
        className={classNames(styles.Tooltip, className)}
        {...styles.dataIsInteractable(isInteractable)}
        {...tooltipProps}
      >
        {children}
        <OverlayArrow>
          <svg width={12} height={12} viewBox="0 0 8 8" className={styles.ArrowIcon}>
            <path d="M0 0 L4 4 L8 0" fill="currentColor" />
          </svg>
        </OverlayArrow>
      </Tooltip>
    </TooltipTrigger>
  );
}
