interface ScriptIconProps extends React.ComponentPropsWithRef<'svg'> {
  color?: string;
}

export const ScriptIcon = ({ color = 'currentColor', ...props }: ScriptIconProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.999 12.75H9.99902"
        stroke={color}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.999 8.75H9.99902"
        stroke={color}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.999 17.75V5.75C18.999 5.21957 18.7883 4.71086 18.4132 4.33579C18.0382 3.96071 17.5295 3.75 16.999 3.75H3.99902"
        stroke={color}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 21.75H20C20.5304 21.75 21.0391 21.5393 21.4142 21.1642C21.7893 20.7891 22 20.2804 22 19.75V18.75C22 18.4848 21.8946 18.2304 21.7071 18.0429C21.5196 17.8554 21.2652 17.75 21 17.75H11C10.7348 17.75 10.4804 17.8554 10.2929 18.0429C10.1054 18.2304 10 18.4848 10 18.75V19.75C10 20.2804 9.78929 20.7891 9.41421 21.1642C9.03914 21.5393 8.53043 21.75 8 21.75ZM8 21.75C7.46957 21.75 6.96086 21.5393 6.58579 21.1642C6.21071 20.7891 6 20.2804 6 19.75V5.75C6 5.21957 5.78929 4.71086 5.41421 4.33579C5.03914 3.96071 4.53043 3.75 4 3.75C3.46957 3.75 2.96086 3.96071 2.58579 4.33579C2.21071 4.71086 2 5.21957 2 5.75V7.75C2 8.01522 2.10536 8.26957 2.29289 8.45711C2.48043 8.64464 2.73478 8.75 3 8.75H6"
        stroke={color}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
