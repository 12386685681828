import classNames from 'classnames';
import { Heading } from 'react-aria-components';

import * as styles from './ModalHeadingText.css';
import { modalLayoutSlots } from './ModalLayout';

export const ModalHeadingText = ({
  className,
  ...props
}: React.ComponentPropsWithoutRef<typeof Heading>) => (
  <Heading
    slot={modalLayoutSlots.title}
    className={classNames(styles.ModalHeadingText, className)}
    {...props}
  />
);
